import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { getUA } from 'react-device-detect'
import { styled, useMediaQuery, useTheme } from '@mui/material'
// components
import AppBanner from 'components/commons/AppBanner'
import Typography from 'components/commons/Typography'
import LayoutMobile from 'components/commons/Layout'
import Header from 'components/commons/Header/BaseHeader'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'
// containers
import Footer from 'containers/commons/Footer'
import LoginForm from 'containers/domains/User/Login'
// hooks
import useAuth from 'hooks/domains/User/useAuth'
import useReady from 'hooks/commons/useReady'
// stores
import { useReferralStore } from 'stores/domains/User'
import { useStoreState } from 'stores/hooks'
// helpers
import trackEvent from 'src/trackers'
import { redirectWithReferrer } from 'helpers/redirector'
import { getPreviousURL } from 'helpers/routing'
import redirectLoggedInUser from 'helpers/auth/redirect-logged-in-user'
import { setUserDevice } from 'helpers/user-device'
import { destroySessionStorage } from 'helpers/session-storage'
// contants & others
import { TAB_STATE_SUB_NAMES } from 'constants/mixpanel/auth'
import TipTipLogo from 'assets/logos/logo.svg'
import {
  destroyLocalStorage,
  getLocalStorage,
} from 'helpers/local-storage'
import { getSSOToken } from 'services/authentication'
import afterLogin from 'helpers/auth/after-login'
import { sendLog } from 'helpers/log'

const Container = styled(Stack)(({ theme }) => ({
  padding: 0,
  margin: 0,
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.primary,
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.background.secondary,
  },
}))

const TopHeader = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.secondary,
  boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,

  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.text.primary,
  },
}))

export default function Login() {
  const { mounted } = useReady()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobileResolution = useMediaQuery(
    theme.breakpoints.down('sm'),
  )
  const { pathname, query, isReady } = useRouter()
  const [showAppBanner, setShowAppBanner] = useState(true)
  const trackerData = { pathname, query }
  const session_id = getLocalStorage('session_id')

  const { isLoading } = useAuth()
  const { currentTab } = useStoreState((state) => state.phoneNumber)

  const signUpBonusBanner = useStoreState(
    (state) => state.signUpBonusBanner,
  )

  const { clearReferralCodeInfo } = useReferralStore((state) => ({
    clearReferralCodeInfo: state.clearReferralCodeInfo,
  }))

  const redirectURL = (url: string) => {
    trackEvent.user('click_sign_in', trackerData, {
      state_sub_name: TAB_STATE_SUB_NAMES[currentTab],
    })
    setUserDevice()
    redirectWithReferrer(url, false)
  }

  const handleBackButton = () => {
    trackEvent.user('cancel_sign_in', trackerData, {
      state_sub_name: TAB_STATE_SUB_NAMES[currentTab],
    })
    destroySessionStorage('retryJoinCommunity')
    clearReferralCodeInfo()
    redirectWithReferrer(
      getPreviousURL(pathname, 'trigger_back'),
      false,
    )
  }

  const handleCheckSSO = async () => {
    try {
      const response = await getSSOToken(session_id)
      if (response?.data?.code === 'SUCCESS') {
        const token = response?.data?.data?.id_token
        const refreshToken = response?.data?.data?.refresh_token
        afterLogin({ token, refreshToken }, () => {
          destroyLocalStorage('session_id')
          redirectLoggedInUser(getPreviousURL(pathname))
        })
      }
    } catch (error) {
      sendLog(error)
    }
  }

  useEffect(() => {
    if (isReady) {
      if (session_id) {
        handleCheckSSO()
      }
      redirectLoggedInUser(getPreviousURL(pathname))
      trackEvent.user('visit_sign_in', trackerData, {
        state_sub_name: TAB_STATE_SUB_NAMES[currentTab],
      })
      setUserDevice()
    }
  }, [isReady, pathname, query])

  if (!mounted) return null

  if (!isMobileResolution) {
    return (
      <Container>
        <TopHeader>
          <TipTipLogo />
        </TopHeader>
        <Stack
          direction={'row'}
          gap={6}
          sx={{
            justifyContent: 'space-around',
            margin: 'auto',
            paddingTop: '32px',
            maxWidth: '1140px',
          }}
        >
          <StyledBigLogo>
            <img src={`/img/webp/login_big_logo.webp`} />
          </StyledBigLogo>
          <LoginForm
            userAgent={getUA}
            isLoading={isLoading}
            sxContainer={{
              maxWidth: '536px',
              height: 'max-content',
              background: 'white',
              boxShadow: `0px 0px 24px 0px rgba(145, 158, 171, 0.24)`,
              borderRadius: '12px',
              padding: '24px',
              position: 'relative',
            }}
          />
        </Stack>
      </Container>
    )
  }

  return (
    <LayoutMobile
      header={{
        isSticky: true,
        component: (
          <>
            <Header
              title=""
              inLogin={isMobileResolution ? showAppBanner : false}
              onBack={handleBackButton}
              style={{
                top: signUpBonusBanner.elementHeight,
                padding: '0px !important',
              }}
              fullWidth
            />
            {isMobileResolution && (
              <AppBanner
                onClose={() => {
                  setShowAppBanner(false)
                }}
              />
            )}
          </>
        ),
        appBanner: isMobileResolution ? showAppBanner : false,
        inLogin: isMobileResolution ? showAppBanner : false,
      }}
      footer={{
        component: (
          <Footer
            isFixed
            style={{
              background: theme.palette.background.secondary,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <StyledTypography>
              {t('noAccount')}
              <StyledTypography
                style={{
                  color: theme.palette.tiptip[500],
                  fontWeight: 700,
                  lineHeight: '16px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  trackEvent.user('click_sign_up', trackerData, {
                    state_sub_name: TAB_STATE_SUB_NAMES[currentTab],
                  })
                  redirectURL('/sign-up')
                }}
                as="span"
              >
                {' '}
                {t('signUp')}
              </StyledTypography>
            </StyledTypography>
          </Footer>
        ),
      }}
    >
      <LoginForm userAgent={getUA} isLoading={isLoading} />
    </LayoutMobile>
  )
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
  display: 'inline',
}))

const StyledBigLogo = styled(Box)({
  maxWidth: '472px',
  img: {
    height: 'auto',
    width: '100%',
  },
})
