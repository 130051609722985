import { useForm } from 'react-hook-form'

// components
import Box from 'components/commons/Box'
import BottomDrawer from 'components/commons/BottomDrawer'
import MessageBox from 'components/commons/MessageBox'
import Attention from 'components/commons/Attention'
import StateImage from 'components/commons/StateImage'
import Loader from 'components/commons/Layout/Loader'
import ReferralCodeInfo from 'components/commons/ReferralCodeInfo'
import LoginFooter from 'components/domains/User/LoginFooter'
import LoginBottomDrawer from 'components/domains/User/LoginBottomDrawer'
import IncompatibleBrowserInfo from 'components/domains/User/IncompatibleBrowserInfo'
import {
  AuthPhoneForm,
  AuthEmailForm,
  EmailFormData,
  PhoneFormData,
} from 'components/domains/User/AuthForm'

//styled
import {
  Container,
  StyledBox,
  StyledSubmitButton,
  StyledSubtitle,
  StyledTab,
  StyledTabs,
  StyledTitle,
} from './styled'

import afterLogin from 'helpers/auth/after-login'
import { redirectWithReferrer } from 'helpers/redirector'
import isEmpty from 'helpers/is-empty'
import {
  setSessionStorage,
  destroySessionStorage,
} from 'helpers/session-storage'
import trackEvent from '../../../../trackers'
import { useRouter } from 'next/router'
import { setUserDevice } from 'helpers/user-device'
import { useStoreActions, useStoreState } from 'stores/hooks'
import {
  TAB_SIGN_IN_EVENTS,
  TAB_STATE_SUB_NAMES,
} from 'constants/mixpanel/auth'
import { forceDeleteCookies } from 'helpers/cookie'
import authConfig from 'configs/auth'
import { sendLog } from 'helpers/log'
import { useAuthStore, useReferralStore } from 'stores/domains/User'
import {
  requestOtpForPhone,
  signInForEmail,
} from 'services/authentication'
import { verificationMethodConst } from 'constants/common'
import { authErrorConst } from 'constants/auth-error'
import { renderErrorMessage } from 'helpers/auth/error-auth'
import {
  destroyLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import { encrypt } from 'helpers/encryption'
import useAuth from 'hooks/domains/User/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { useFeatureFlags } from 'helpers/feature-flag'
import {
  styled,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Typography from 'components/commons/Typography'
import { useTranslation } from 'react-i18next'

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
  display: 'inline',
}))

const StyledFooterLogin = styled(Box)(({ theme }) => ({
  background: theme.palette.background.secondary,
  position: 'absolute',
  bottom: 0,
  width: '100%',
  left: 0,
  textAlign: 'center',
  padding: '14px',
  borderRadius: '0px 0px 12px 12px',
}))

interface ILoginFormProps {
  userAgent?: string
  isLoading?: boolean
  sxContainer?: SxProps
}

const ERROR_MESSAGES = {
  NOT_FOUND: 'Nomor Handphone Belum Terdaftar, Daftar Sekarang',
}

function Login(props: ILoginFormProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { pathname, query } = useRouter()
  const isMobileResolution = useMediaQuery(
    theme.breakpoints.down('sm'),
  )
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [messageBox, setMessageBox] = useState('')
  const [showInternalErrorAlert, setShowInternalErrorAlert] =
    useState(false)

  const isEncryptPassword = useFeatureFlags('encrpyt-password')

  const trackerData = { pathname, query }
  const DEFAULT_ERROR_MESSAGE = t('key_something_wrong_try_again')

  const { errorCode } = useAuth()
  const { currentTab } = useStoreState((actions) => ({
    ...actions.phoneNumber,
  }))
  const {
    setVerificationAction,
    inputPhoneNumber,
    countryData,
    resetCountryData,
  } = useAuthStore((state) => ({
    setVerificationAction: state.setVerificationAction,
    inputPhoneNumber: state.inputPhoneNumber,
    countryData: state.countryData,
    resetCountryData: state.resetCountryData,
  }))
  const { setShowReferralInfoDrawer } = useReferralStore((state) => ({
    setShowReferralInfoDrawer: state.setShowReferralInfoDrawer,
  }))

  const { setUserPhone, setCurrentTab } = useStoreActions(
    (actions) => ({
      ...actions.phoneNumber,
    }),
  )

  // handle login with phone number
  const formPhone = useForm<PhoneFormData>({
    mode: 'onChange',
  })
  const {
    formState: formStatePhone,
    setError: setErrorPhone,
    clearErrors: clearErrorsPhone,
    getValues,
    setValue,
  } = formPhone
  const {
    isValid: isValidPhone,
    isDirty: isDirtyPhone,
    errors: errorsPhone,
  } = formStatePhone

  const isTabEmailActive = useCallback(
    () =>
      currentTab === 1 || getLocalStorage('authMethod') === 'email',
    [currentTab],
  )

  const referralFromLink = !isEmpty(getLocalStorage('referralCode'))

  const onSubmitWithPhone = async (method: number) => {
    destroySessionStorage('isNewUser')
    const areaCode = getValues('areaCode')
    const phoneNumber = getValues('phoneNumber')

    try {
      setIsLoading(true)
      destroyLocalStorage('userEmail')
      const phoneWithAreaCode = `${areaCode}${+phoneNumber}`
      setPhoneNumber(phoneWithAreaCode)
      setLocalStorage('authMethod', 'phoneNumber')
      setLocalStorage('userPhoneNumber', phoneWithAreaCode)
      setLocalStorage('countryData', countryData)
      setLocalStorage('verificationMethod', {
        method: verificationMethodConst[method],
      })

      setVerificationAction('SIGN_IN')

      const res = await requestOtpForPhone({
        phone_number: phoneWithAreaCode,
        action: 'SIGN_IN',
        delivery_method: verificationMethodConst[method],
      })

      if (res?.data?.code === 'SUCCESS') {
        resetCountryData()
        if (referralFromLink) {
          setShowReferralInfoDrawer(true)
        }
        redirectWithReferrer(
          `/code-verification/phone?source=${pathname}&source_action=submit_phone_number`,
          false,
        )
      }
    } catch (error) {
      setIsLoading(false)
      if (
        ['NOT_FOUND', 'FOUND_IN_GOOGLE_ACCOUNT'].includes(
          error.response.data.code,
        )
      ) {
        setErrorPhone('afterSubmit', {
          ...error.response.data,
          message: '',
        })
        setShowUnregisteredAlert(true)
        return
      }
      if (error.code === 'UserNotFoundException') {
        setUserPhone({
          codeArea: areaCode,
          phone: phoneNumber,
        })
        return
      }
      if (
        error.message?.toLowerCase().includes('revoked') ||
        error.message?.toLowerCase().includes('invalid refresh token')
      ) {
        forceDeleteCookies()
        setTimeout(() => {
          window.location.reload()
        }, authConfig.clearCookiesTimeout)
        return
      }

      if (authErrorConst?.[error.response.data.code]) {
        const errMessage = renderErrorMessage(
          error.response.data.code?.toUpperCase(),
          {
            seconds: error.response.data?.data?.retry_in_secs,
            defaultMessage: error.response.data.message,
          },
        )
        setErrorPhone('phoneNumber', {
          type: 'custom',
          message: t(errMessage),
        })
        return
      }

      const errorMessage = ERROR_MESSAGES[error.response.data.code]
      sendLog(errorMessage)

      setErrorPhone('afterSubmit', {
        ...error,
        message: errorMessage
          ? errorMessage
          : error?.response?.data?.message ||
            error.message ||
            DEFAULT_ERROR_MESSAGE,
      })
    }
  }

  // handle login with email
  const form = useForm<EmailFormData>({
    mode: 'onChange',
  })
  const {
    handleSubmit: handleSubmitWithEmail,
    formState,
    setError,
    clearErrors,
  } = form
  const { isValid, isDirty, errors } = formState
  const [showUnregisteredAlert, setShowUnregisteredAlert] =
    useState(false)

  const onSubmitWithEmail = async (data: EmailFormData) => {
    try {
      setIsLoading(true)
      destroySessionStorage('isNewUser')
      destroyLocalStorage('userPhoneNumber')
      setEmail(data.email)

      const securePassword = encrypt(data.password)
      setLocalStorage('authMethod', 'email')
      setLocalStorage('userEmail', data.email)
      setSessionStorage('userPassword', securePassword)

      const res = await signInForEmail({
        email: data.email,
        password: isEncryptPassword
          ? window.btoa(data.password)
          : data.password,
      })

      if (res?.data?.code === 'SUCCESS') {
        const { id_token: token, refresh_token: refreshToken } =
          res.data.data
        if (referralFromLink) {
          setShowReferralInfoDrawer(true)
        }

        afterLogin({ token, refreshToken }, () => {
          destroySessionStorage('userPassword')
          redirectWithReferrer(
            '/user/post-registration',
            false,
            true,
            true,
          )
        })
      }
    } catch (error) {
      setIsLoading(false)
      sendLog(error.message)
      if (error.code === 'UserNotConfirmedException') {
        redirectWithReferrer('/code-verification/email', false)
        return
      } else if (
        error.message?.toLowerCase().includes('revoked') ||
        error.message?.toLowerCase().includes('invalid refresh token')
      ) {
        //force logout if error
        forceDeleteCookies()
        setTimeout(() => {
          window.location.reload()
        }, authConfig.clearCookiesTimeout)
      } else {
        const errMessage = renderErrorMessage(
          error.response.data.code?.toUpperCase(),
          {
            defaultMessage:
              error.response.data.message || DEFAULT_ERROR_MESSAGE,
            isEmail: true,
          },
        )
        setError('afterSubmit', {
          ...error,
          message: t(errMessage),
        })
      }
    }
  }

  function handleChangeTab(value: number) {
    setCurrentTab(value)
    trackEvent.user(TAB_SIGN_IN_EVENTS[value], trackerData, {
      state_sub_name: TAB_STATE_SUB_NAMES[value],
    })
  }

  const doSignUp = () => {
    trackEvent.user('click_sign_up', trackerData, {
      modal_name: isTabEmailActive()
        ? 'EMAIL_NOT_REGISTERED'
        : 'PHONE_NUMBER_NOT_REGISTERED',
      state_sub_name: TAB_STATE_SUB_NAMES[currentTab],
    })
    setUserDevice()
    redirectWithReferrer('/sign-up', false)
  }

  const handleSubmitAction = () => {
    doSignUp()
  }

  const handleDisableRegisterButton = () => {
    if (isTabEmailActive()) {
      return !isDirty || !isValid
    }
    return !isDirtyPhone || !isDirtyPhone
  }

  const redirectURL = (url: string) => {
    trackEvent.user('click_sign_in', trackerData, {
      state_sub_name: TAB_STATE_SUB_NAMES[currentTab],
    })
    setUserDevice()
    redirectWithReferrer(url, false)
  }

  const getDescriptionLoginDrawer = () => {
    if (
      !!errorsPhone.afterSubmit &&
      errorsPhone.afterSubmit['code'] === 'FOUND_IN_GOOGLE_ACCOUNT'
    ) {
      return isTabEmailActive()
        ? t('signInGoogleSsoDesc', { phone: phoneNumber })
        : t('signInGoogleSsoDesc', { phone: phoneNumber })
    }
    return isTabEmailActive()
      ? t('continueSignUpWithEmail', { email: email })
      : t('continueSignUpWithPhoneNumber', { phone: phoneNumber })
  }

  useEffect(() => {
    if (!isEmpty(inputPhoneNumber)) {
      setValue('phoneNumber', inputPhoneNumber, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPhoneNumber])

  useEffect(() => {
    if (errorCode === 'UserNotFoundException') {
      setShowUnregisteredAlert(true)
    }
  }, [errorCode])

  return (
    <>
      {props.isLoading ? (
        <Loader />
      ) : (
        <Container id="p-users-login" sx={props.sxContainer}>
          <StyledBox sx={{ width: '100%', paddingTop: '16px' }}>
            {props.userAgent?.toLowerCase().includes('instagram') && (
              <IncompatibleBrowserInfo />
            )}

            <Box px={1}>
              <StyledTitle>{t('signInTitle')}</StyledTitle>
              <StyledSubtitle>{t('signInDesc')}</StyledSubtitle>
              <StyledTabs
                id="b-users-tabs-button"
                value={currentTab}
                onChange={(_, value) => handleChangeTab(value)}
                textColor="secondary"
                variant="fullWidth"
              >
                <StyledTab label={t('signInPhoneNumberLabel')} />
                <StyledTab label={t('signInUsernameLabel')} />
              </StyledTabs>
            </Box>
            <Box px={1} py={3}>
              <Box display={currentTab === 0 ? 'block' : 'none'}>
                <AuthPhoneForm
                  form={formPhone}
                  onChange={() => {
                    if (!!errorsPhone.afterSubmit) {
                      clearErrorsPhone('afterSubmit')
                    }
                  }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  {!!errorsPhone.afterSubmit &&
                    errorsPhone.afterSubmit.message && (
                      <MessageBox
                        open={true}
                        onClose={() =>
                          clearErrorsPhone('afterSubmit')
                        }
                        isError={true}
                        message={errorsPhone.afterSubmit.message}
                      />
                    )}
                  <StyledSubmitButton
                    buttonVariant="filled"
                    disableElevation
                    fullWidth
                    id="b-users-login-continue-with-phone"
                    disabled={!isDirtyPhone || !isValidPhone}
                    loading={isLoading}
                    onClick={() => onSubmitWithPhone(0)}
                  >
                    {t('continueText')}
                  </StyledSubmitButton>
                </AuthPhoneForm>
              </Box>
              <Box display={currentTab === 1 ? 'block' : 'none'}>
                <AuthEmailForm
                  onSubmit={handleSubmitWithEmail(onSubmitWithEmail)}
                  form={form}
                  onChange={() => {
                    if (!!errors.afterSubmit) {
                      clearErrors('afterSubmit')
                    }
                  }}
                >
                  {!!errors.afterSubmit &&
                    errors.afterSubmit.message && (
                      <MessageBox
                        open={true}
                        onClose={() => clearErrors('afterSubmit')}
                        isError={true}
                        message={errors.afterSubmit.message}
                      />
                    )}
                  <StyledSubmitButton
                    id="b-users-login-continue-with-email"
                    type="submit"
                    buttonVariant="filled"
                    disableElevation
                    fullWidth
                    disabled={!isDirty || !isValid}
                    loading={isLoading}
                  >
                    {t('continueText')}
                  </StyledSubmitButton>
                </AuthEmailForm>
              </Box>
              {!isEmpty(messageBox) && (
                <MessageBox
                  open={!isEmpty(messageBox)}
                  onClose={() => setMessageBox('')}
                  isError={true}
                  message={messageBox}
                />
              )}
            </Box>
            <LoginFooter
              renderForgotPassword
              renderSSO={
                !props.userAgent?.toLowerCase().includes('instagram')
              }
            />
          </StyledBox>

          <BottomDrawer
            background="linear-gradient(270deg, #CB1C4F 0%, #FF223E 100%)"
            content={
              <Attention
                title={t('internalErrorTitle')}
                desc={t('internalErrorDesc')}
              >
                <StateImage type="error" />
              </Attention>
            }
            hideHeader
            showDrawer={showInternalErrorAlert}
            onCloseAction={() => setShowInternalErrorAlert(false)}
            onSubmitAction={() => setShowInternalErrorAlert(false)}
            submitLabel={t('tryAgain')}
          />
          <LoginBottomDrawer
            background="white"
            content={
              <Attention
                title={
                  !!errorsPhone.afterSubmit &&
                  errorsPhone.afterSubmit['code'] ===
                    'FOUND_IN_GOOGLE_ACCOUNT'
                    ? t('signInWithGoogleSso')
                    : `${
                        isTabEmailActive()
                          ? t('emailNotRegisterTitle')
                          : t('phoneNumberNotRegisterTitle')
                      }`
                }
                desc={getDescriptionLoginDrawer()}
              >
                <StateImage type="access-permission" />
              </Attention>
            }
            hideHeader
            showDrawer={showUnregisteredAlert}
            onCloseAction={() => setShowUnregisteredAlert(false)}
            onSubmitAction={handleSubmitAction}
            secondBtnLabel={t('change')}
            onSecondBtnAction={() => {
              isTabEmailActive()
                ? trackEvent.user('click_change_email', trackerData, {
                    modal_name: 'EMAIL_NOT_REGISTERED',
                  })
                : trackEvent.user(
                    'click_change_phone_number',
                    trackerData,
                    {
                      modal_name: 'PHONE_NUMBER_NOT_REGISTERED',
                    },
                  )
              setUserDevice()
              setShowUnregisteredAlert(false)
            }}
            submitLabel={
              !!errorsPhone.afterSubmit &&
              errorsPhone.afterSubmit['code'] ===
                'FOUND_IN_GOOGLE_ACCOUNT'
                ? 'Google SSO'
                : t('switchSignUp')
            }
            hideThirdButton={false}
            thirdBtnLabel={
              !!errorsPhone.afterSubmit &&
              errorsPhone.afterSubmit['code'] ===
                'FOUND_IN_GOOGLE_ACCOUNT'
                ? undefined
                : t('signInWithGoogleSso')
            }
            onThirdBtnAction={
              !!errorsPhone.afterSubmit &&
              errorsPhone.afterSubmit['code'] ===
                'FOUND_IN_GOOGLE_ACCOUNT' &&
              undefined
            }
            isRequesting={handleDisableRegisterButton()}
          />
          <ReferralCodeInfo />
          {!isMobileResolution && (
            <StyledFooterLogin>
              <StyledTypography>
                {t('noAccount')}
                <StyledTypography
                  style={{
                    color: theme.palette.tiptip[500],
                    fontWeight: 700,
                    lineHeight: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    trackEvent.user('click_sign_up', trackerData, {
                      state_sub_name: TAB_STATE_SUB_NAMES[currentTab],
                    })
                    redirectURL('/sign-up')
                  }}
                  as="span"
                >
                  {' '}
                  {t('signUp')}
                </StyledTypography>
              </StyledTypography>
            </StyledFooterLogin>
          )}
        </Container>
      )}
    </>
  )
}

export default Login
